export default function MouseCursor() {
    return (
        <div className="mouse-cursor" id="mouse-cursor">
            <div className="mc-b">
                <svg className="mc-loupe" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
                    <path
                        d="m51.6 96.7c11 0 21-3.9 28.8-10.5l35 35c0.8 0.8 1.8 1.2 2.9 1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-35-35c6.5-7.8 10.5-17.9 10.5-28.8 0-24.9-20.2-45.1-45.1-45.1-24.8 0-45.1 20.3-45.1 45.1 0 24.9 20.3 45.1 45.1 45.1zm0-82c20.4 0 36.9 16.6 36.9 36.9 0 20.4-16.5 36.9-36.9 36.9s-36.9-16.6-36.9-36.9 16.6-36.9 36.9-36.9z">
                    </path>
                </svg>
                <svg className="mc-cart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
                    <path
                        d="m10.7 99.5h4.8c-3.3 5.9-2.5 13.5 2.5 18.5 2.9 2.9 6.8 4.5 11 4.5s8-1.6 11-4.5c2.9-2.9 4.5-6.8 4.5-11 0-2.7-0.7-5.2-2-7.5h19.3c-3.3 5.9-2.5 13.5 2.5 18.5 2.9 2.9 6.8 4.5 11 4.5s8-1.6 11-4.5c2.9-2.9 4.5-6.8 4.5-11 0-2.7-0.7-5.2-2-7.5h5.2c2.3 0 4.1-1.8 4.1-4.1 0-2.3-1.8-4.1-4.1-4.1h-79.2v-8h90c1.8 0 3.4-1.2 3.9-3l13.6-49.7c0.3-1.2 0.1-2.5-0.7-3.5-0.8-1-2-1.6-3.2-1.6h-103.6v-14.9c0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1v18.9 49.7 16.1c0 2.4 1.8 4.2 4.1 4.2zm59.3 2.3c1.4-1.4 3.2-2.2 5.2-2.2 2 0 3.8 0.8 5.2 2.2 1.4 1.4 2.2 3.2 2.2 5.2 0 2-0.8 3.8-2.2 5.2-2.8 2.8-7.6 2.8-10.4 0-2.9-2.9-2.9-7.5 0-10.4zm-46.2 0c1.4-1.4 3.2-2.2 5.2-2.2 2 0 3.8 0.8 5.2 2.2 1.4 1.4 2.2 3.2 2.2 5.2 0 2-0.8 3.8-2.2 5.2-2.8 2.8-7.6 2.8-10.4 0-2.9-2.9-2.9-7.5 0-10.4zm-9-68.2h98.2l-11.4 41.6h-86.8v-41.6z">
                    </path>
                </svg>
                <svg className="mc-cancel" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
                    <path
                        d="M7.6 121.4c0.8 0.8 1.8 1.2 2.9 1.2s2.1-0.4 2.9-1.2l51.1-51.1 51.1 51.1c0.8 0.8 1.8 1.2 2.9 1.2 1 0 2.1-0.4 2.9-1.2 1.6-1.6 1.6-4.2 0-5.8L70.3 64.5l51.1-51.1c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8 0L64.5 58.7 13.4 7.6C11.8 6 9.2 6 7.6 7.6s-1.6 4.2 0 5.8l51.1 51.1L7.6 115.6C6 117.2 6 119.8 7.6 121.4z">
                    </path>
                </svg>
                <svg className="mc-next" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
                    <path
                        d="m40.4 121.3c-0.8 0.8-1.8 1.2-2.9 1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8 0l53.9 53.9c1.6 1.6 1.6 4.2 0 5.8l-53.9 53.9z">
                    </path>
                </svg>
                <svg className="mc-prev" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129">
                    <path
                        d="m88.6 121.3c0.8 0.8 1.8 1.2 2.9 1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8 0l-54 53.9c-1.6 1.6-1.6 4.2 0 5.8l54 53.9z">
                    </path>
                </svg>
                <svg className="mc-play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
                    <path
                        d="M36.1 20.2l-29-20C6.8 0 6.4-0.1 6 0.1 5.7 0.3 5.5 0.6 5.5 1v40c0 0.4 0.2 0.7 0.5 0.9 0.1 0.1 0.3 0.1 0.5 0.1 0.2 0 0.4-0.1 0.6-0.2l29-20c0.3-0.2 0.4-0.5 0.4-0.8S36.3 20.4 36.1 20.2zM7.5 39.1V2.9l26.2 18.1L7.5 39.1z">
                    </path>
                </svg>
            </div>
        </div>
    )
}